import React from "react";
import styled from "@emotion/styled";
import Container from "@src/components/Container";
import { GatsbyImage } from "gatsby-plugin-image";
import { BodyText, Space, Title, theme, Button } from "@src/components/nessie-web";
import { graphql, useStaticQuery } from "gatsby";
import Tile from "@src/components/Tile";
import { mediaQueries } from "@src/styles/theme";

const HeroSectionStyled = styled.div`
  overflow: hidden;
  padding-bottom: 60px;
  padding-top: 22px;
  background-color: ${theme.colors.dt_taro10};
`;

const ListTitle = ({ children }) => (
  <Title color="dt_taro50" textAlign="center">
    {children}
  </Title>
);

const Image = styled(GatsbyImage)`
  width: 100%;
  max-width: 400px;
  margin: auto;
`;

const CtaTile = styled(Tile)`
  margin-top: 180px;
`;

const AbsoluteImage = styled(Image)`
  margin-top: -150px;
`;

const ENGINEERING_PHILSOPHY = [
  `We value engineer empowerment and autonomy: we want to allow the amazing people we hire to make a difference!`,
  `We're a fully remote organization`,
  `We value generalists, especially generalists with expertise in one or two areas. Generalists enable small cross-functional teams that can own their work end-to-end`,
  `Things will go wrong: we value recovering quickly over preventing failure at all costs, and we run blameless post-mortems whenever there are failures`,
  `We value boring technology: we want to use a limited set of well-understood & proven languages and tools to accomplish our goals.`,
  `In general, we avoid micro-services & love our monolith`,
];

const ENGINEERING_PRACTICES = [
  `Continuous Integration & Continuous Delivery; trunk based development; feature flags. We deploy many times per day!`,
  `We value automated tests & monitoring much more than manual testing. We don't have "test engineers"`,
  `Zero defect policy`,
  `We try to optimize for long-term engineering speed & quality`,
  `We value collaboration & having multiple engineers tackle a problem together`,
];

const TECH_STACK = [
  `TypeScript servers backed by MySQL, MongoDB, and Redis`,
  `Consul & Nomad for Docker container orchestration`,
  `HAProxy load balancer`,
  `We run almost everything on AWS`,
  `React/TypeScript for web frontends`,
  `Flutter + Kotlin for android clients`,
  `native iOS client`,
  `Redshift, Airflow, and Metabase for data analysis`,
];

export default function AboutEngineering() {
  const { aboutFirstImage, aboutSecondImage } = useStaticQuery(graphql`
    {
      aboutFirstImage: file(name: { eq: "about1" }) {
        childImageSharp {
          gatsbyImageData(width: 400, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      aboutSecondImage: file(name: { eq: "about2" }) {
        childImageSharp {
          gatsbyImageData(width: 400, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <HeroSectionStyled>
      <div style={{ width: "100%", maxWidth: 740, margin: "auto" }}>
        <Image image={aboutFirstImage.childImageSharp.gatsbyImageData} />
        <Space size="xl" />
        <ListTitle>Engineering Philosophy</ListTitle>
        <Space size="xl" />
        <ul>
          {ENGINEERING_PHILSOPHY.map((text, i) => (
            <li key={i}>
              <BodyText>{text}</BodyText>
            </li>
          ))}
        </ul>
        <Space size="xxl" />
        <ListTitle>Engineering Practices</ListTitle>
        <Space size="xl" />
        <ul>
          {ENGINEERING_PRACTICES.map((text, i) => {
            return (
              <li key={i}>
                <BodyText>{text}</BodyText>
              </li>
            );
          })}
        </ul>
        <Space size="xxl" />
        <ListTitle>Tech Stack</ListTitle>
        <Space size="xl" />
        <ul>
          {TECH_STACK.map((text, i) => {
            return (
              <li key={i}>
                <BodyText>{text}</BodyText>
              </li>
            );
          })}
        </ul>
        <CtaTile size="l">
          <AbsoluteImage image={aboutSecondImage.childImageSharp.gatsbyImageData} />
          <Space size="m" />
          <Title textAlign="center">Want to help ensure every child gets and education they love?</Title>
          <Title>Work with us!</Title>
          <Space size="m" />
          <Button href="https://www.classdojo.com/jobs">See our open positions</Button>
        </CtaTile>
      </div>
    </HeroSectionStyled>
  );
}
